import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './shared/components/callback/callback.component';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './shared/services/interceptor.service';
import { AuthGuard } from './shared/guard/auth.guard';
import { ServerErrorComponent } from './shared/components/server-error/server-error.component';
import { FaqComponent } from './shared/components/faq/faq.component';
import { canLoadMigrationRouteGuard } from './shared/guard/migration.guard';
import { canLoadNewPartsShopRouteGuard } from './shared/guard/parts-shop.guard';

const routes: Routes = [
  { path: 'callback', component: CallbackComponent },
  {
    path: 'logoutCallback',
    loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'support',
    loadChildren: () => import('./features/support/support.module').then((m) => m.SupportModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'machines',
    loadChildren: () => import('./features/machines/machines.module').then((m) => m.MachinesModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'analytics',
    loadChildren: () => import('./features/analytics/analytics.module').then((m) => m.AnalyticsModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'analytics-reports',
    loadChildren: () => import('./features/analytics/analytics-reports-routes').then((m) => m.ANALYTICS_REPORTS_ROUTES),
    canLoad: [AuthGuard],
  },
  {
    path: 'analytics-comparison',
    loadChildren: () => import('./features/analytics/analytics-comparison-routes').then((m) => m.ANALYTICS_COMPARISON_ROUTES),
    canLoad: [AuthGuard],
  },
  {
    path: 'connections',
    loadChildren: () => import('./features/connections/connections.module').then((m) => m.ConnectionsModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'configurations',
    loadChildren: () => import('./features/configurations/configurations.module').then((m) => m.ConfigurationsModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'shops',
    loadChildren: () => import('./features/shops/shops.module').then((m) => m.ShopsModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'parts-shop',
    loadChildren: () => import('./features/parts-shop/parts-shop-routes').then((m) => m.PARTS_SHOP_ROUTES),
    canLoad: [canLoadNewPartsShopRouteGuard],
  },
  {
    path: 'privacy',
    loadChildren: () => import('./features/legal-texts/legal-texts.module').then((m) => m.LegalTextsModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'operations',
    loadChildren: () => import('./features/operations/operations.module').then((m) => m.OperationsModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'file-transfer',
    loadChildren: () => import('./features/file-transfer/file-transfer-routes').then((m) => m.FILE_TRANSFER_ROUTES),
    canLoad: [AuthGuard],
  },
  {
    path: 'license',
    loadChildren: () => import('./features/license/license-routes').then((m) => m.LICENSE_ROUTES),
    canLoad: [AuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./features/notifications/notifications-routes').then((m) => m.NOTIFICATIONS_ROUTES),
    canLoad: [AuthGuard],
  },
  {
    path: 'migration',
    loadChildren: () => import('./features/migration/routes').then((m) => m.MIGRATION_ROUTES),
    canLoad: [canLoadMigrationRouteGuard],
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  { path: 'server-error', component: ServerErrorComponent },
  { path: 'faq', component: FaqComponent },
  { path: '**', loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule), canLoad: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
