import { inject } from '@angular/core';
import { UrlTree, Route, UrlSegment, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as RootState from '../state';

import { getFeatureToggles, getFeatureTogglesInitialized } from '../state/feature-toggle';
import { Features } from '../models/feature-toggle.model';
import { AuthGuard } from './auth.guard';

export function canLoadNewPartsShopRouteGuard(
  route: Route,
  segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  const router = inject(Router);
  const store = inject(Store<RootState.IState>);
  const authGuard = inject(AuthGuard);

  const hasFeatureToggle$ = combineLatest([
    authGuard.canLoad(route, segments) as Promise<boolean | UrlTree>,
    store.select(getFeatureToggles),
    store.select(getFeatureTogglesInitialized)
  ]).pipe(
    filter(([authorizedCanLoad, featureToggles, featureTogglesInitialized]) => authorizedCanLoad && featureTogglesInitialized),
    map(([auth, featureToggles, featureTogglesInitialized]) => featureToggles?.some((toggle) =>
      toggle.id === Features.NEW_PARTS_SHOP) ?? false),
    tap((hasFeatureToggle) => {
      if (!hasFeatureToggle) {
        void router.navigate(['dashboard']);
      }
    })
  );

  return hasFeatureToggle$;
}
