import {
  IAddressAutocompleteRequest,
  IDealerByNameRequest,
  IDealerDataRegistrationParams,
  IDealerRegistrationRequest,
  INearestDealersRequest,
  IPersonalDataRegistrationRequest,
  IRegisterOrganisationRequestWithMoreParams,
  IRegistrationCurrentOrganisationAddress,
  IShopsRegistrationRequestWithParams,
  IOrganisationListItem,
  IRegisterMigrationOrganisationRequestWithLocaleParams,
  IAddressDetailsRequest,
} from 'cde-fe-organization-registration-dialog';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { mergeMap, switchMap, map, catchError, finalize, tap, withLatestFrom, first, delay, filter } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as OrganisationsActions from './organisations.actions';
import * as ShopActions from '../shops/shops.actions';
import * as FeatureToggleActions from '../feature-toggle/feature-toggle.actions';
import { RegisterMigrationOrganisationSuccess, RegisterOrganisationSuccess } from './organisations.actions';
import * as AuthActions from '@app/shared/state/auth/auth.actions';
import * as DashboardActions from '@app/shared/state/dashboard/dashboard.actions';
import * as RootState from '../';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { OrganizationService } from '@app/shared/services/organization.service';
import { AuthServiceWrapper } from '@app/shared/services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import {
  IRegisterMigrationOrganisationResult,
  IRegisterOrganisationResultWithDealerAndShop,
  Role,
} from '@app/shared/models/organisation.model';
import { ResetAnalytics } from '../analytics/analytics.actions';
import { ResetConfigurations } from '../configs/configs.actions';
import { ResetConnections } from '../connections/connections.actions';
import { ResetDashboard } from '@app/shared/state/dashboard/dashboard.actions';
import { ResetDealer } from '../dealer/dealer.actions';
import { ResetFilters } from '../filter/filter.actions';
import { ResetMachineDetails } from '../machine-details/machine-details.actions';
import { ResetMachineOverview } from '../machine-overview/machines.actions';
import { ResetShops } from '../shops/shops.actions';
import { getCountryByAlpha2, getLocaleByAlpha2 } from 'country-locale-map';
import { MaintenanceTextService } from '@app/shared/services/maintenance-text.service';
import { IOrganizationMigrationProposal } from '@app/shared/models/migration.model';

@Injectable()
export class OrganisationsEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private organisationService = inject(OrganizationService);
  private notificationService = inject(NotificationsService);
  private auth = inject(AuthServiceWrapper);
  private maintenanceTextService = inject(MaintenanceTextService);
  private platformId = inject<number | string>(PLATFORM_ID);
  rehydrate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganisationsActions.REHYDRATE_STATE),
        tap(() => {
          this.store.dispatch(new OrganisationsActions.List({ rehydrate: true }));
          this.store.dispatch(new FeatureToggleActions.GetFeatureToggles());
        })
      ),
    { dispatch: false }
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.LIST),
      withLatestFrom(this.store),
      map(
        (
          data: [OrganisationsActions.List, RootState.IState]
        ): [IOrganisationListItem[], boolean | undefined, string | undefined, boolean | undefined] => {
          const periodically = data[0].context?.periodically;
          const rehydrate = data[0].context?.rehydrate;
          const orgState = data[1].organisations;
          const currentOrgId = orgState.lastSuccess?.auth0Id;
          return [orgState.organisationListItems, periodically, currentOrgId, rehydrate];
        }
      ),
      mergeMap(([storeOrganisations, periodically, currentOrgId, rehydrate]) =>
        this.organisationService.list().pipe(
          map((organisations: IOrganisationListItem[]) => {
            if (periodically === false) {
              return new OrganisationsActions.ListSuccess(organisations, rehydrate);
            } else {
              const currentOrgIds = organisations.map((org) => org.auth0Id).sort();
              const storeOrgIds = storeOrganisations.map((org) => org.auth0Id).sort();
              const localStorageOrgId = window.localStorage.getItem('currentOrganisationId');
              const currentStoreOrg = storeOrganisations.find((storeOrg) => storeOrg.auth0Id === currentOrgId);
              if (
                currentStoreOrg === null ||
                localStorageOrgId === null ||
                localStorageOrgId !== currentStoreOrg?.auth0Id ||
                JSON.stringify(currentOrgIds) !== JSON.stringify(storeOrgIds)
              ) {
                return new OrganisationsActions.ListSuccess(organisations, rehydrate);
              }
              return new OrganisationsActions.ListComplete();
            }
          }),
          catchError((error) => {
            if (rehydrate && (error.data?.message as string)?.toLowerCase().includes('external interaction required')) {
              return of(new OrganisationsActions.List({ rehydrate: false, periodically }));
            }

            return of(new OrganisationsActions.ListFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new OrganisationsActions.ListComplete());
          })
        )
      )
    )
  );

  listSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganisationsActions.LIST_SUCCESS),
        map(
          (data: { payload: IOrganisationListItem[]; rehydrate?: boolean }) =>
            [data.payload, data.rehydrate] as [IOrganisationListItem[], boolean]
        ),
        tap(([organisations, rehydrate]: [IOrganisationListItem[], boolean]) => {
          let checkWithNewToken = false;
          if (isPlatformBrowser(this.platformId)) {
            this.auth
              .getTokenSilently$()
              .pipe(first())
              .subscribe((token) => {
                const orgId = this.auth.getAuth0OrgFromToken(token);
                const orgExists = organisations.some((org) => org.auth0Id === orgId);
                if (orgExists === false) {
                  const firstOrgId = organisations?.[0]?.auth0Id;
                  checkWithNewToken = true;
                  this.auth
                    .getNewToken$(firstOrgId)
                    .pipe(first())
                    .subscribe((newToken: string) => {
                      this.store.dispatch(
                        new OrganisationsActions.SetCurrentOrganisation(
                          {
                            auth0Id: firstOrgId,
                          },
                          rehydrate != null ? !rehydrate : true,
                          rehydrate,
                          rehydrate
                        )
                      );
                      this.setUserPermissions(newToken);
                      this.maintenanceTextService.getMaintenanceTexts();
                    });
                } else {
                  this.store.dispatch(
                    new OrganisationsActions.SetCurrentOrganisation(
                      {
                        auth0Id: orgId,
                      },
                      rehydrate != null ? !rehydrate : true,
                      rehydrate,
                      rehydrate
                    )
                  );
                  this.maintenanceTextService.getMaintenanceTexts();
                }
                if (!checkWithNewToken) {
                  this.setUserPermissions(token);
                }
              });
          } else {
            this.store.dispatch(new OrganisationsActions.RehydrateStateComplete());
          }
        })
      ),
    { dispatch: false }
  );

  getNotificationCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_NOTIFICATION_COUNT),
      switchMap(() =>
        this.notificationService.getCountForOrganisations().pipe(
          map((res) => new OrganisationsActions.GetNotificationCountSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetNotificationCountFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetNotificationCountComplete()))
        )
      )
    )
  );

  setCurrentOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.SET_CURRENT_ORGANISATION),
      map(
        (action: OrganisationsActions.SetCurrentOrganisation) =>
          [action.payload, action.preventRefresh, action.removeBackdrop, action.rehydrate] as [
            Partial<IOrganisationListItem> | null,
            boolean,
            boolean,
            boolean
          ]
      ),
      tap(
        ([organisation, preventRefresh, removeBackdrop, rehydrate]: [Partial<IOrganisationListItem> | null, boolean, boolean, boolean]) => {
          if (organisation !== null) {
            if (!removeBackdrop) {
              this.store.dispatch(new OrganisationsActions.SetOrganisationChanging(true));
            }

            if (!preventRefresh) {
              this.auth
                .getNewToken$(organisation.auth0Id)
                .pipe(first())
                .subscribe((newToken: string) => {
                  this.store.dispatch(new AuthActions.GetUser(organisation.auth0Id, true));

                  if (rehydrate) {
                    this.store.dispatch(new OrganisationsActions.RehydrateStateComplete());
                  }
                });
            } else {
              this.store.dispatch(new OrganisationsActions.RehydrateStateComplete());
            }
          }
        }
      ),
      map(
        ([organisation, preventRefresh, removeBackdrop, rehydrate]: [Partial<IOrganisationListItem> | null, boolean, boolean, boolean]) => {
          if (preventRefresh) {
            this.store.dispatch(new OrganisationsActions.SetOrganisationChanging(false));
          }

          // if (organisation?.country) this.store.dispatch(new OrganisationsActions.SetOrganisationLocale(organisation.country));

          return new OrganisationsActions.SetCurrentOrganisationSuccess({
            auth0Id: organisation?.auth0Id,
            name: organisation?.name,
            default: organisation?.default,
            notificationCount: organisation?.notificationCount,
            timeZone: organisation?.timeZone,
          });
        }
      )
    )
  );

  getUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.GET_USER_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [AuthActions.GetUserSuccess, RootState.IState]) => {
          const authState = store.auth;
          const token = authState.token === undefined ? null : authState.token;
          this.setUserPermissions(token);

          if (action.setNewCurrentOrg) {
            this.restartStore();
          }
        })
      ),
    { dispatch: false }
  );

  getRegistrationDefaultOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_REGISTRATION_DEFAULT_ORGANISATION),
      map((action: OrganisationsActions.GetRegistrationDefaultOrganisation) => action.country),
      switchMap((country: string) =>
        this.organisationService.getRegistrationDefaultOrganisation(country).pipe(
          map((res) => new OrganisationsActions.GetRegistrationDefaultOrganisationSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetRegistrationDefaultOrganisationFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetRegistrationDefaultOrganisationComplete()))
        )
      )
    )
  );

  getCurrentOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_CURRENT_ORGANISATION),
      map((action: OrganisationsActions.GetCurrentOrganisation) => action.orgId),
      switchMap((orgId: string) =>
        this.organisationService.getCurrentOrganisation(orgId).pipe(
          map((res) => {
            this.store.dispatch(new OrganisationsActions.SetOrganisationChanging(false));
            this.store.dispatch(new OrganisationsActions.SetOrganisationLocale(res.country));
            return new OrganisationsActions.GetCurrentOrganisationSuccess(res);
          }),
          catchError((error) => of(new OrganisationsActions.GetCurrentOrganisationFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetCurrentOrganisationComplete()))
        )
      )
    )
  );

  getAddressAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_ADDRESS_AUTOCOMPLETE),
      map((action: OrganisationsActions.GetAddressAutocomplete) => action.payload),
      mergeMap((addressAutocompleteRequest: IAddressAutocompleteRequest) =>
        this.organisationService.getAddressAutocomplete(addressAutocompleteRequest).pipe(
          map((response) => new OrganisationsActions.GetAddressAutocompleteSuccess(response)),
          catchError((error) => of(new OrganisationsActions.GetAddressAutocompleteFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetAddressAutocompleteComplete()))
        )
      )
    )
  );

  getOrganizationMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_MEMBERS),
      map((action: OrganisationsActions.GetOrganizationMembers) => action.payload),
      switchMap((orgId: string) =>
        this.organisationService.getMembersOfOrganization(orgId).pipe(
          map((res) => new OrganisationsActions.GetOrganizationMembersSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetRegistrationDefaultOrganisationFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetOrganizationMembersComplete()))
        )
      )
    )
  );

  registerOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.REGISTER_ORGANISATION),
      map(
        (action: OrganisationsActions.RegisterOrganisation) =>
          [action.payload, action.removeBackdrop] as [IRegisterOrganisationRequestWithMoreParams, boolean]
      ),
      tap(([registerOrganisationRequest, removeBackdrop]: [IRegisterOrganisationRequestWithMoreParams, boolean]) => {
        if (!removeBackdrop) {
          this.store.dispatch(new OrganisationsActions.SetOrganisationChanging(true));
        }
      }),
      switchMap(([registerOrganisationRequest, removeBackdrop]: [IRegisterOrganisationRequestWithMoreParams, boolean]) =>
        this.organisationService.registerOrganisation(registerOrganisationRequest).pipe(
          map(
            (response) =>
              new OrganisationsActions.RegisterOrganisationSuccess(
                {
                  response,
                  // demo org cannot be upgraded anymore and thus we always create
                  // a new organisation
                  newOrganisation: true,
                  country: registerOrganisationRequest.country,
                  shops: registerOrganisationRequest.request.shops,
                  dealer: registerOrganisationRequest.request.dealer,
                  organisationAddress: registerOrganisationRequest.request.address,
                },
                removeBackdrop
              )
          ),
          catchError((error) => {
            this.store.dispatch(new OrganisationsActions.SetOrganisationChanging(false));
            return of(new OrganisationsActions.RegisterOrganisationFailure(error));
          }),
          finalize(() => this.store.dispatch(new OrganisationsActions.RegisterOrganisationComplete()))
        )
      )
    )
  );

  registerMigrationOrganisation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.REGISTER_MIGRATION_ORGANISATION),
      map(
        (action: OrganisationsActions.RegisterMigrationOrganisation) =>
          [action.payload, action.removeBackdrop] as [IRegisterMigrationOrganisationRequestWithLocaleParams, boolean]
      ),
      tap(([registerOrganisationRequest, removeBackdrop]: [IRegisterMigrationOrganisationRequestWithLocaleParams, boolean]) => {
        if (!removeBackdrop) {
          this.store.dispatch(new OrganisationsActions.SetOrganisationChanging(true));
        }
      }),
      mergeMap(([registerOrganisationRequest, removeBackdrop]: [IRegisterMigrationOrganisationRequestWithLocaleParams, boolean]) =>
        this.organisationService.registerMigrationOrganisation(registerOrganisationRequest).pipe(
          map(
            (response) =>
              new OrganisationsActions.RegisterMigrationOrganisationSuccess(
                {
                  response,
                  // demo org cannot be upgraded anymore and thus we always create
                  // a new organisation
                  newOrganisation: true,
                  country: registerOrganisationRequest.country,
                  shops: registerOrganisationRequest.request.shops,
                  dealer: registerOrganisationRequest.request.dealer,
                  organisationAddress: registerOrganisationRequest.request.address,
                },
                removeBackdrop
              )
          ),
          catchError((error) => {
            this.store.dispatch(new OrganisationsActions.SetOrganisationChanging(false));
            return of(new OrganisationsActions.RegisterMigrationOrganisationFailure(error));
          }),
          finalize(() => this.store.dispatch(new OrganisationsActions.RegisterMigrationOrganisationComplete()))
        )
      )
    )
  );

  registerOrganisationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganisationsActions.REGISTER_ORGANISATION_SUCCESS, OrganisationsActions.REGISTER_MIGRATION_ORGANISATION_SUCCESS),
        map(
          (action: RegisterOrganisationSuccess | RegisterMigrationOrganisationSuccess) =>
            [action.payload, action.removeBackdrop] as [
              IRegisterOrganisationResultWithDealerAndShop | IRegisterMigrationOrganisationResult,
              boolean
            ]
        ),
        delay(0), // ensure cache refresh for retrieving a new user token and dispatching a /authorize call
        tap(([payload, removeBackdrop]: [IRegisterOrganisationResultWithDealerAndShop | IRegisterMigrationOrganisationResult, boolean]) => {
          // set current organisation is not triggered by the dialog anymore as
          // the corresponding event is triggered only when the newly created
          // org is registered as changed and then the SetCurrentOrg action is
          // dispatched. This does not happen anymore as the dialog is closed
          // and thus the event is not triggered.
          this.store.dispatch(
            new OrganisationsActions.SetCurrentOrganisation(
              {
                auth0Id: payload.response.auth0Id,
              },
              false,
              removeBackdrop
            )
          );
        }),
        delay(0),
        tap(([payload, removeBackdrop]: [IRegisterOrganisationResultWithDealerAndShop | IRegisterMigrationOrganisationResult, boolean]) => {
          this.store.dispatch(new AuthActions.GetUser(payload.response.auth0Id, true));
          this.store.dispatch(new OrganisationsActions.GetCurrentOrganisation(payload.response.auth0Id));
          this.store.dispatch(new OrganisationsActions.GetRegistrationDefaultOrganisation(payload.country));
          this.store.dispatch(new OrganisationsActions.GetRegistrationShopData(payload.country));
          this.store.dispatch(
            new DashboardActions.GetDashboardsAfterOrgCreation(
              payload.response.id,
              payload.shops.map((s) => s.shopId),
              payload.dealer,
              payload.organisationAddress as IRegistrationCurrentOrganisationAddress
            )
          );
          this.store.dispatch(new OrganisationsActions.List());
        })
      ),
    { dispatch: false }
  );

  getRegistrationPersonalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_REGISTRATION_PERSONAL_DATA),
      map((action: OrganisationsActions.GetRegistrationPersonalData) => [action.country, action.language] as [string, string]),
      switchMap(([country, language]: [string, string]) =>
        this.organisationService.getRegistrationPersonalData(country, language).pipe(
          map((res) => new OrganisationsActions.GetRegistrationPersonalDataSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetRegistrationPersonalDataFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetRegistrationPersonalDataComplete()))
        )
      )
    )
  );

  maintainPersonalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.MAINTAIN_REGISTRATION_PERSONAL_DATA),
      map(
        (action: OrganisationsActions.MaintainRegistrationPersonalData) =>
          [action.payload, action.language] as [IPersonalDataRegistrationRequest, string]
      ),
      switchMap(([request, language]: [IPersonalDataRegistrationRequest, string]) =>
        this.organisationService.maintainRegistrationPersonalData(request, language).pipe(
          map((response) => new OrganisationsActions.MaintainRegistrationPersonalDataSuccess(response)),
          catchError((error) => of(new OrganisationsActions.MaintainRegistrationPersonalDataFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.MaintainRegistrationPersonalDataComplete()))
        )
      )
    )
  );

  getRegistrationDealerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_REGISTRATION_DEALER_DATA),
      map((action: OrganisationsActions.GetRegistrationDealerData) => action.payload),
      switchMap((params: IDealerDataRegistrationParams) =>
        this.organisationService.getRegistrationDealerData(params).pipe(
          map((res) => new OrganisationsActions.GetRegistrationDealerDataSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetRegistrationDealerDataFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetRegistrationDealerDataComplete()))
        )
      )
    )
  );

  registerOrganisationDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.REGISTER_ORGANISATION_DEALER),
      map(
        (action: OrganisationsActions.RegisterOrganisationDealer) =>
          [action.payload, action.language] as [IDealerRegistrationRequest, string]
      ),
      switchMap(([request, language]: [IDealerRegistrationRequest, string]) =>
        this.organisationService.registerOrganisationDealer(request, language).pipe(
          map((response) => new OrganisationsActions.RegisterOrganisationDealerSuccess(response)),
          catchError((error) => of(new OrganisationsActions.RegisterOrganisationDealerFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.RegisterOrganisationDealerComplete()))
        )
      )
    )
  );

  getRegistrationShopData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_REGISTRATION_SHOP_DATA),
      map((action: OrganisationsActions.GetRegistrationShopData) => ({ country: action.payload, orgId: action.orgId })),
      switchMap(({ country, orgId }) =>
        this.organisationService.getRegistrationShopData(country, orgId).pipe(
          map((res) => new OrganisationsActions.GetRegistrationShopDataSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetRegistrationShopDataFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetRegistrationShopDataComplete()))
        )
      )
    )
  );

  registerOrganisationShops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.REGISTER_ORGANISATION_SHOPS),
      map(
        (action: OrganisationsActions.RegisterOrganisationShops) =>
          [action.payload, action.isChangeRequest] as [IShopsRegistrationRequestWithParams, boolean]
      ),
      switchMap(([requestParam, isChangeRequest]: [IShopsRegistrationRequestWithParams, boolean]) =>
        this.organisationService.registerOrganisationShops(requestParam.request, requestParam.country).pipe(
          map((response) => new OrganisationsActions.RegisterOrganisationShopsSuccess(response, isChangeRequest)),
          catchError((error) => of(new OrganisationsActions.RegisterOrganisationShopsFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.RegisterOrganisationShopsComplete()))
        )
      )
    )
  );

  refreshAfterOrgChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrganisationsActions.SET_CURRENT_ORGANISATION_SUCCESS,
        OrganisationsActions.REGISTER_ORGANISATION_SHOPS_SUCCESS,
        ShopActions.UNREGISTER_ORGANISATION_SHOP_SUCCESS
      ),
      filter((action: ShopActions.UnregisterOrganisationShopSuccess) => {
        const isNoUnregisterAction = action.deleteShop == null && action.newDealer == null;
        if (isNoUnregisterAction) {
          return true;
        }

        return action.deleteShop != null && action.newDealer == null;
      }),
      withLatestFrom(this.store),
      map((context: [Action, RootState.IState]) => context[1].organisations.currentOrganisation?.country ?? ''),
      filter((country: string) => country.length > 0),
      map((country: string) => new OrganisationsActions.GetRegistrationShopData(country))
    )
  );

  getRegistrationDialogSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_REGISTRATION_DIALOG_STEPS),
      switchMap(() =>
        this.organisationService.getRegistrationDialogSteps().pipe(
          map((res) => new OrganisationsActions.GetRegistrationDialogStepsSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetRegistrationDialogStepsFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetRegistrationDialogStepsComplete()))
        )
      )
    )
  );

  getNearestDealers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_NEAREST_DEALERS),
      map((action: OrganisationsActions.GetNearestDealers) => action.payload),
      switchMap((request: INearestDealersRequest) =>
        this.organisationService.getNearestDealers(request).pipe(
          map((res) => new OrganisationsActions.GetNearestDealersSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetNearestDealersFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetNearestDealersComplete()))
        )
      )
    )
  );

  getDealersByName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_DEALERS_BY_NAME),
      map((action: OrganisationsActions.GetDealersByName) => action.payload),
      switchMap((request: IDealerByNameRequest) =>
        this.organisationService.getDealersByName(request).pipe(
          map((res) => new OrganisationsActions.GetDealersByNameSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetDealersByNameFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetDealersByNameComplete()))
        )
      )
    )
  );

  getOrganizationsConnectedDealers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_ORGANISATION_CONNECTED_DEALERS),
      map((action: OrganisationsActions.GetOrganisationConnectedDealers) => action.payload),
      switchMap((request: string) =>
        this.organisationService.getOrganisationsConnectedDealers(request).pipe(
          map((res) => new OrganisationsActions.GetOrganisationConnectedDealersSuccess(res)),
          catchError((error) => of(new OrganisationsActions.GetOrganisationConnectedDealersFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetOrganisationConnectedDealersComplete()))
        )
      )
    )
  );

  getAddressDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_ADDRESS_DETAILS),
      map((action: OrganisationsActions.GetAddressDetails) => action.payload),
      mergeMap((addressDetailsRequest: IAddressDetailsRequest) =>
        this.organisationService.getAddressDetails(addressDetailsRequest).pipe(
          map((response) => new OrganisationsActions.GetAddressDetailsSuccess(response)),
          catchError((error) => of(new OrganisationsActions.GetAddressDetailsFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetAddressDetailsComplete()))
        )
      )
    )
  );

  getOrganisationRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.GET_ORGANISATION_ROLES),
      withLatestFrom(this.store),
      map(
        (context: [OrganisationsActions.GetOrganisationRoles, RootState.IState]) =>
          [context[0].roles, context[0].userId ?? context[1].auth.user?.uuid] as [Role[], string]
      ),
      mergeMap(([roles, userId]: [Role[], string]) =>
        this.organisationService.getOrganisationRoles(roles, userId).pipe(
          map((organisationRoles) => new OrganisationsActions.GetOrganisationRolesSuccess(organisationRoles)),
          catchError((error) => of(new OrganisationsActions.GetOrganisationRolesFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.GetOrganisationRolesComplete()))
        )
      )
    )
  );

  setOrganisationLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.SET_ORGANISATION_LOCALE),
      map((action: OrganisationsActions.SetOrganisationLocale) => action.payload),
      map((country) => {
        const locale = getLocaleByAlpha2(country.toUpperCase());
        if (!locale) {
          return 'en-gb';
        }

        // kyrillic locales can be built up like "kk_Cyrl_KZ". If we have more
        // than the usual lang_country locale we calculate the language as the
        // fallback
        if (locale.split('_').length > 2) {
          const countryData = getCountryByAlpha2(country.toUpperCase());
          return countryData?.languages[0];
        }

        return locale?.replace('_', '-') ?? 'en-gb';
      }),
      map((locale) => new OrganisationsActions.SetOrganisationLocaleSuccess(locale))
    )
  );

  searchAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.SEARCH_ADDRESS),
      map((action: OrganisationsActions.SearchAddress) => action.request),
      mergeMap((request: IAddressAutocompleteRequest) =>
        this.organisationService.searchAddress(request).pipe(
          map((response) => new OrganisationsActions.SearchAddressSuccess(response)),
          catchError((error) => of(new OrganisationsActions.SearchAddressFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.SearchAddressComplete()))
        )
      )
    )
  );

  checkIfOrgAlreadyExistsForDebitorId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActions.CHECK_IF_ORG_ALREADY_EXISTS_FOR_DEBITOR_ID),
      map((action: OrganisationsActions.CheckIfOrgAlreadyExistsForDebitorId) => action.proposal),
      mergeMap((proposal: IOrganizationMigrationProposal) =>
        this.organisationService.checkIfOrgAlreadyExistsForDebitorId(proposal).pipe(
          map((response) => new OrganisationsActions.CheckIfOrgAlreadyExistsForDebitorIdSuccess(response)),
          catchError((error) => of(new OrganisationsActions.CheckIfOrgAlreadyExistsForDebitorIdFailure(error))),
          finalize(() => this.store.dispatch(new OrganisationsActions.CheckIfOrgAlreadyExistsForDebitorIdComplete()))
        )
      )
    )
  );

  private setUserPermissions(token: string | null) {
    const permissions = this.auth.getAuth0UserPermsFromToken(token);
    this.store.dispatch(new OrganisationsActions.SetUserPermissionsSuccess(permissions));
    this.store.dispatch(new OrganisationsActions.GetNotificationCount());
  }

  private restartStore() {
    // // RESET STORE
    this.store.dispatch(new ResetAnalytics());
    this.store.dispatch(new ResetConfigurations());
    this.store.dispatch(new ResetConnections());
    this.store.dispatch(new ResetDashboard());
    this.store.dispatch(new ResetDealer());
    this.store.dispatch(new ResetFilters());
    this.store.dispatch(new ResetMachineDetails());
    this.store.dispatch(new ResetMachineOverview());
    this.store.dispatch(new ResetShops());
  }
}
