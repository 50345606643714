import { Injectable, inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { RequestParam, ServiceCall } from './service-call.service';
import {
  IConnection,
  IConnectionWithScopedCouplings,
  IConsent,
  ICoupling,
  IDataConnectInboundAuth,
  IDataConnectInboundEquipmentList,
  IDataConnectInboundContainerPut,
  IDataConnectInboundCouplingList,
} from '@app/shared/models/connections.model';
import { Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';

export const CONNECTIONS_URL = 'v1/connections';

@Injectable({
  providedIn: 'root',
})
export class ConnectionsService {
  private serviceCall = inject(ServiceCall);
  private connectionsURL = `${environment.apiBaseUrl}/${CONNECTIONS_URL}`;

  getConnections(languageCode: string): Observable<IConnection[]> {
    let params: RequestParam = new HttpParams();
    params = params.set('languageCode', languageCode);
    return this.serviceCall
      .get(`${this.connectionsURL}`, params)
      .pipe(
        map((response) => <IConnection[]>response.data));
  }

  getConnection(id: string, languageCode: string): Observable<IConnectionWithScopedCouplings> {
    let params: RequestParam = new HttpParams();
    params = params.set('languageCode', languageCode);
    return this.serviceCall.get(`${this.connectionsURL}/${id}`, params).pipe(
      map((response) => ({
        ...(<IConnection>response.data),
        couplingForScope: this.getCouplingsForScopes(<IConnection>response.data),
      })),
    );
  }

  getPartnerLogo(connectionId: string): Observable<{ id: string; logoUrl: string }> {
    return this.serviceCall.getImage(`${this.connectionsURL}/${connectionId}/partner/logo`).pipe(
      catchError((error) => {
        if (error.data.status === 404) {
          return of({ data: null });
        }
        return of(undefined);
      }),
      map(
        (response) =>
          <
          { id: string; logoUrl: string } // construct aURL from the blob, so we can bind it to an image: <img [href}="logoUrl"
          >{ id: connectionId, logoUrl: response?.data ? URL.createObjectURL(<Blob>response.data) : null },
      ),
    );
  }

  revokeCoupling(connection: IConnection, coupling: ICoupling): Observable<any> {
    return this.serviceCall.patch(`${this.connectionsURL}/${connection.id}/couplings/${coupling.id}`, {
      state: 'revoked',
    });
  }

  getCouplingsForScopes(connection: IConnection | undefined | null): Map<string, ICoupling[] | null | undefined> {
    const couplingsForScope: Map<string, ICoupling[] | null | undefined> = new Map();
    if (!connection) {
      return couplingsForScope;
    }
    for (const scope of connection.partner.scopes) {
      couplingsForScope.set(
        scope,
        connection.couplings.filter((it) => it.scope?.split(' ').includes(scope)),
      );
    }
    return couplingsForScope;
  }

  getDataConnectCouplings(connectionId: string): Observable<IDataConnectInboundCouplingList> {
    return this.serviceCall
      .get(`${this.connectionsURL}/${connectionId}/scopes/dataconnect-inbound/couplings`)
      .pipe(map((response) => <IDataConnectInboundCouplingList>response.data));
  }

  getDataConnectInboundAuth(
    connectionId: string | undefined,
    state?: string,
    code?: string,
    error?: string,
    errorDescription?: string,
  ): Observable<IDataConnectInboundAuth> {
    let params = new HttpParams();

    if (state) {
      params = params.append('state', state);
    }
    if (code) {
      params = params.append('code', code);
    }

    if (error) {
      params = params.append('error', error);
    }

    if (errorDescription) {
      params = params.append('errorDescription', errorDescription);
    }

    return this.serviceCall
      .get(`${this.connectionsURL}/${connectionId}/scopes/dataconnect-inbound/couplings/auth`, params)
      .pipe(map((response) => <IDataConnectInboundAuth>response.data));
  }

  fetchEquipment(
    connectionId: string,
    containerId: string,
    couplingId: string,
  ): Observable<IDataConnectInboundEquipmentList> {
    const params = new HttpParams({
      fromObject: { containerId: containerId ? containerId : '' },
    });

    const equipmentUrl = `${this.connectionsURL}/${connectionId}/scopes/dataconnect-inbound/couplings/${couplingId}/equipment`;

    return this.serviceCall
      .get(equipmentUrl, params)
      .pipe(map((response) => <IDataConnectInboundEquipmentList>response.data));
  }

  putDataConnectContainer(
    connectionId: string,
    couplingId: string,
    payload: IDataConnectInboundContainerPut,
  ): Observable<any> {
    return this.serviceCall.put(
      `${this.connectionsURL}/${connectionId}/scopes/dataconnect-inbound/couplings/${couplingId}/container`,
      payload,
    );
  }

  deleteDataConnectCoupling(connectionId: string, couplingId: string): Observable<any> {
    return this.serviceCall.delete(
      `${this.connectionsURL}/${connectionId}/scopes/dataconnect-inbound/couplings/${couplingId}`,
    );
  }
}
